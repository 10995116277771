<template>
  <b-modal
    id="cancel-filings-modal"
    title="Filing Cancellation"
    size="lg"
    footer-class="justify-content-center"
  >
    <slot name="body">
      <div v-if="hasComplianceOrderItems">
        <p>
          Failure to timely file renewal reports for your entity can affect your business' legal standing
          and could result in additional charges from the state.
        </p>
        <p>
          {{ complianceCancelText }}
        </p>

        <div v-if="hasComplianceServices">
          <p>
            Cancel the filing service if you would also like to cancel all future filings and automatic payments for that jurisdiction.
          </p>
          <ct-table
            ref="complianceServicesTable"
            :items="complianceServices"
            :fields="serviceFields"
            :selectable-function="selectable"
            :selectable="true"
            select-mode="multi"
            :is-paginated="false"
            :loaded="!loading"
            @selection-changed="selectionEvent"
          />
        </div>
      </div>
    </slot>
    <div v-if="selectedServices.length > 0">
      <p>
        <span>
          <strong>Representative agrees;</strong>
          that this is binding on the company(s), and that if someone has access to this account,
          whomever agrees to cancel, has the proper authority.
        </span>
      </p>
      <p>
        <span>
          <strong>Representative agrees;</strong>
          that this is a civil agreement and waives any and all rights or claims of statutory damages
          or tort claims.
        </span>
      </p>
      <p>
        <strong>Representative agrees;</strong>
        to release any and all liability or duty we had or continue to have to complete any annual
        or biennial compliance filings with the state.
      </p>
      <p>
        <strong>Representative agrees;</strong>
        that as of today, the company has cancelled compliance service offered by us.
      </p>
      <strong>Representative</strong>
      <p class="m-b-lg">
        <i>
          By typing your name, you are agreeing to the end of service agreement. You are agreeing this
          is the equivalent of a traditional signature. This will cancel the selected services with
          us.
        </i>
      </p>
      <b-form-group>
        <label class="label-lg">* Enter your name to agree</label>
        <b-form-input v-model="signature" type="text" class="form-control" required />
      </b-form-group>
    </div>
    <slot v-if="selectedServices.length === 0">
      <p>Are you sure you would like to cancel the filing?</p>
    </slot>
    <template v-slot:modal-footer>
      <b-button
        v-if="!loading"
        :disabled="cancelling || needSignature"
        class="submit-btn-width"
        size="md"
        variant="danger"
        aria-label="cancel button"
        @click="cancelItems"
      >
        <b-spinner v-if="cancelling" small />
        <div v-else>
          {{ cancelText }}
        </div>
      </b-button>
      <ct-centered-spinner v-if="loading" />
    </template>
  </b-modal>
</template>

<script>
  import axiosClient from '../http'
  import CtTable from './shared/CtTable'
  import { formatDateStringFromSeconds } from '@/common/modules/formatters'
  import CtCenteredSpinner from './shared/CtCenteredSpinner'

  export default {
    name: 'CancelFilingsModal',
    components: {
      CtTable,
      CtCenteredSpinner,
    },
    props: {
      bus: {
        type: Object,
      },
    },
    data() {
      return {
        loading: false,
        orderItemIds: [],
      complianceOrderItems: [],
        complianceServices: [],
        selectedServices: [],
        signature: null,
        cancelling: false,
        serviceFields: [
          { key: 'company.name', label: 'Company' },
          { key: 'product.name', label: 'Type', sortable: true },
          { key: 'status', label: 'Status', sortable: true },
          { key: 'jurisdiction.state_province_region', label: 'Jurisdiction' },
          { key: 'stop', label: 'End of Service', formatter: value => this.dateTableColumn(value), sortable: true },
        ],
      }
    },
    computed: {
      cancelText() {
        let text = 'Cancel '
        if (this.orderItemIds.length == 1) {
          text += 'Filing'
        } else {
          text += 'Filings'
        }
        if (this.selectedServices.length == 1) {
          text += ' and Service'
        } else if (this.selectedServices.length > 1) {
          text += ' and Services'
        }
        return text
      },
      needSignature() {
        return this.selectedServices.length > 0 && this.invalidSignature()
      },
      complianceCancelText() {
        if (this.complianceOrderItems.length === 1) {
           return "You have requested to cancel a renewal filing. Canceling the filing will only " +
             "cancel the order for the date and jurisdiction you've selected."
        } else {
          return "You have requested to cancel renewal filings. Canceling the filings will only " +
            "cancel the orders for the dates and jurisdictions you've selected."
        }
      },
      hasComplianceOrderItems() {
        return this.complianceOrderItems.length > 0
      },
      hasComplianceServices() {
        return this.complianceServices.length > 0
      },

    },
    async mounted() {
      this.bus.$on('openCancelFilings', this.open)
    },
    methods: {
      async open(orderItems) {
        this.orderItemIds     = []
        this.selectedServices = []
        this.complianceOrderItems = []
        this.loading    = true
        this.cancelling = false
        this.$bvModal.show('cancel-filings-modal')

        this.complianceOrderItems = orderItems.filter(o => ['initial report', 'compliance filing'].includes(o.product.filing_name))

        const response = await axiosClient.get(`client/services/compliance`, {
          params: {
            order_item_ids: this.complianceOrderItems.map( o => o.id),
          },
        })
        this.orderItemIds = orderItems.map( o => o.id )
        this.complianceServices = response.data.result
        this.loading = false
      },
      selectionEvent(selection) {
        this.selectedServices = selection
      },
      selectable() {
        return true
      },
      dateTableColumn(date) {
        return date === 'Invalid Date' ? '---' : this.formatDate(date)
      },
      invalidSignature() {
        return !this.signature || this.signature === ''
      },
      async cancelItems() {
        if (this.selectedServices.length === 0) {
          this.signature = ''
        }
        try {
          this.cancelling = true
          await axiosClient.post('client/cancellations/cancel_services_and_filings', {
            cancel: {
              service_ids: this.selectedServices.map(s => s.id),
              order_item_ids: this.orderItemIds,
              signature: this.signature,
            },
          })
          this.$bvToast.toast('Cancellation Requested', { title: 'Success', variant: 'success' })
        } catch(error) {
          this.$bvToast.toast(error.response.data.error.message,
            { title: 'Error', variant: 'danger' })
        }
        this.cancelling = false
        this.$bvModal.hide('cancel-filings-modal')
        this.$emit('completed')
      },
      formatDate: formatDateStringFromSeconds,
    },
  }
</script>

<style lang="scss" scoped>

</style>
